<template>
  <div style="padding: 20px">
    <div class="query-list" style="padding-bottom: 20px">
      <el-row :gutter="20" type="flex" align="bottom" justify="space-between">
        <el-col :span="18" :offset="0">
          <el-row :gutter="20">
            <el-col :span="4" :offset="0">
              <el-input v-model="buyer_id" placeholder="请输入买家ID" size="normal"></el-input>
            </el-col>
            <el-col :span="4" :offset="0">
              <el-input
                v-model="buyer_mobile"
                placeholder="请输入买家手机号"
                size="normal"
              ></el-input>
            </el-col>
            <el-col :span="4" :offset="0">
              <el-input v-model="buyer_email" placeholder="请输入买家邮箱" size="normal"></el-input>
            </el-col>
            <el-col :span="4" :offset="0">
              <el-input v-model="order_id" placeholder="订单号" size="normal"></el-input>
            </el-col>
            <el-col :span="4" :offset="0">
              <el-input v-model="nft_tx_id" placeholder="请输入NFT txid" size="normal"></el-input>
            </el-col>
          </el-row>
          <el-row :gutter="20" style="margin-top: 10px">
            <el-col :span="4" :offset="0">
              <el-input v-model="seller_id" placeholder="请输入卖家ID" size="normal"></el-input>
            </el-col>
            <el-col :span="4" :offset="0">
              <el-input
                v-model="seller_mobile"
                placeholder="请输入卖家手机号"
                size="normal"
              ></el-input>
            </el-col>
            <el-col :span="4" :offset="0">
              <el-input
                v-model="seller_email"
                placeholder="请输入卖家邮箱"
                size="normal"
              ></el-input>
            </el-col>
            <el-col :span="4" :offset="0">
              <el-input v-model="nft_name" placeholder="请输入NFT名称" size="normal"></el-input>
            </el-col>
            <el-col :span="4" :offset="0">
              <el-input
                v-model="badge_code"
                placeholder="请输入badge_code"
                size="normal"
              ></el-input>
            </el-col>
          </el-row>
          <el-row :gutter="20" style="margin-top: 10px">
            <el-col>
              <el-date-picker
                v-model="dateRange"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              /> </el-col
          ></el-row>
        </el-col>
        <el-col :span="6" :offset="0" style="text-align: right">
          <el-button type="primary" size="default" @click="handleClickSearch">
            查询
          </el-button>
          <el-button type="info" size="default" @click="handleClickReset">
            重置
          </el-button>
          <el-button type="default" size="default" @click="handleClickExport">
            导出查询结果
          </el-button>
        </el-col>
      </el-row>
    </div>

    <el-table :data="list" border stripe row-key="order_id">
      <el-table-column label="订单号" prop="order_id"> </el-table-column>
      <el-table-column label="NFT作品名" prop="nft_name"> </el-table-column>
      <el-table-column label="预览图" prop="pics" width="320px">
        <template slot-scope="scope">
          <el-image
            v-for="(pic, idx) in scope.row.pics"
            :key="idx"
            :src="pic"
            fit="fill"
            :lazy="true"
            :preview-src-list="scope.row.pics"
            style="max-width: 140px; max-height: 140px; margin-right: 8px"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column label="手机号" prop="buyer_mobile"> </el-table-column>
      <el-table-column label="CNY价格" prop="fiat_price" />
      <!-- TODO 隐藏部分 -->
      <!-- <el-table-column label="BSV价格" prop="price" /> -->
      <el-table-column label="badge_code" prop="badge_code">
        <template slot-scope="scope">
          <div>
            <span>{{ scope.row.badge_code | coverWithEllipsis }}</span>
            <el-button
              circle
              size="mini"
              icon="el-icon-copy-document"
              @click="handleCopy(scope.row.badge_code)"
            ></el-button>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="txid" prop="tx_id">
        <template slot-scope="scope">
          <div>
            <span>{{ scope.row.tx_id | coverWithEllipsis }}</span>
            <el-button
              circle
              size="mini"
              icon="el-icon-copy-document"
              @click="handleCopy(scope.row.tx_id)"
            ></el-button>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="买家信息" prop="buyer_info">
        <template slot-scope="scope">
          <div>
            <span>{{ scope.row.buyer_info }}</span>
            <el-button
              circle
              size="mini"
              icon="el-icon-copy-document"
              @click="handleCopy(scope.row.buyer_info)"
            ></el-button>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="卖家信息" prop="seller_info">
        <template slot-scope="scope">
          <div>
            <span>{{ scope.row.seller_info }}</span>
            <el-button
              circle
              size="mini"
              icon="el-icon-copy-document"
              @click="handleCopy(scope.row.seller_info)"
            ></el-button>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="订单时间" prop="created_at">
        <template slot-scope="scope">
          <div>{{ scope.row.created_at | formatDate }}</div>
        </template>
      </el-table-column>
    </el-table>

    <div style="margin-top: 20px">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getNFTTradeOrders, exportNFTTradeOrders } from "@/api/nft.js";
import dayjs from "dayjs";

export default {
  data() {
    return {
      page: 1,
      pageSize: 20,
      total: 0,
      list: [],
      dateRange: [],
      // 查询参数
      buyer_id: "",
      buyer_mobile: "",
      buyer_email: "",
      seller_id: "",
      seller_mobile: "",
      seller_email: "",
      order_id: "",
      nft_tx_id: "",
      nft_name: "",
      badge_code: "",
    };
  },
  filters: {
    formatDate: function(value) {
      return dayjs(value * 1000).format("YYYY-MM-DD HH:mm:ss");
    },
    coverWithEllipsis: function(value) {
      if (!value || value.length <= 10) {
        return value;
      }

      const start = value.substring(0, 6);
      const end = value.substring(value.length - 4);
      return `${start}...${end}`;
    },
  },
  mounted() {
    const search = new URLSearchParams(window.location.search);
    const badge_code = search.get("badge_code");
    if (badge_code) {
      this.badge_code = badge_code;
    }

    const buyer_id = search.get("buyer_id");
    if (buyer_id) {
      this.buyer_id = buyer_id;
    }

    const seller_id = search.get("seller_id");
    if (seller_id) {
      this.seller_id = seller_id;
    }

    // this.fetchTradeOrders();
  },
  methods: {
    getSearchParams() {
      const params = {
        page: this.page,
        page_size: this.pageSize,
      };

      this.seller_id && (params.seller_id = this.seller_id);
      this.seller_mobile && (params.seller_mobile = this.seller_mobile);
      this.seller_email && (params.seller_email = this.seller_email);

      this.buyer_id && (params.buyer_id = this.buyer_id);
      this.buyer_mobile && (params.buyer_mobile = this.buyer_mobile);
      this.buyer_email && (params.buyer_email = this.buyer_email);

      this.order_id && (params.order_id = this.order_id);
      this.nft_tx_id && (params.nft_tx_id = this.nft_tx_id);
      this.nft_name && (params.nft_name = this.nft_name);
      this.badge_code && (params.badge_code = this.badge_code);

      if (this.dateRange && Object.values(this.dateRange).length) {
        params.start = this.setTimeStart(this.dateRange[0]);
        params.end = this.setTimeEnd(this.dateRange[1]);
      }
      return params;
    },
    async fetchTradeOrders() {
      try {
        const params = this.getSearchParams();
        const { data } = await getNFTTradeOrders(params);
        const { total = 0, items = [] } = data?.data;
        this.total = total;
        this.list = [...items];
      } catch (error) {
        console.log(error);
      }
    },
    async exportTradeOrders() {
      try {
        const params = this.getSearchParams();
        // delete params.page;
        // delete params.page_size;
        const { data } = await exportNFTTradeOrders(params);
        data.data.url && window.open(data.data.url);
      } catch (error) {
        console.log(error);
      }
    },

    setTimeStart(time) {
      return time
        ? dayjs(time)
            .startOf("day")
            .unix()
        : "";
    },
    setTimeEnd(time) {
      return time
        ? dayjs(time)
            .endOf("day")
            .unix()
        : "";
    },

    handleClickSearch() {
      this.page = 1;
      this.pageSize = 20;
      this.fetchTradeOrders();
    },

    handleClickReset() {
      this.resetQuery();
      this.fetchTradeOrders();
    },

    handleClickExport() {
      // todo
      this.exportTradeOrders();
    },

    resetQuery() {
      this.page = 1;
      this.pageSize = 20;
      this.buyer_id = "";
      this.buyer_mobile = "";
      this.buyer_email = "";
      this.seller_id = "";
      this.seller_mobile = "";
      this.seller_email = "";
      this.order_id = "";
      this.nft_tx_id = "";
      this.nft_name = "";
      this.badge_code = "";
    },

    handleCurrentChange(page) {
      this.page = page;
      this.fetchTradeOrders();
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.fetchTradeOrders();
    },
    handleStatusChange() {
      this.page = 1;
      this.pageSize = 20;
      this.fetchTradeOrders();
    },

    handleCopy(text) {
      this.$copyText(text).then(() => {
        this.$message.success("复制成功");
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
